.icon {
  margin-left: 10px;
  margin-right: 10px;
}

.icon:hover{
  cursor: pointer;
}

.hover_blue:hover {
  fill: #53acfa;
}

.hover_red:hover {
  fill: #ff243a;
}