@import "../../components/Variables.scss";

.side-bar-container {
  width: 229px;
  height: 95%;
  background-color: $main-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  position: fixed;
  z-index: 1;
  top: 20px;
}

.side-bar-container-responsive {
  width: 250px;
  height: 100%;
  background-color: $main-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  z-index: 1;
}

.title {
  margin-top: 50px;
  margin-bottom: 30px;
  font-size: $font-size-big;
  font-weight: 500;
  & > a {
    color: white;
    text-decoration: none;
  }
}

.menu-item {
  width: 100%;
  overflow-y: scroll;
}

.menu-item::-webkit-scrollbar {
  display: none;
}

p {
  margin: 0 10px;
}

.sidebar-item {
  cursor: pointer;
  height: 40px;
  width: 100%;
  color: white;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  display: flex;
  align-items: center;
  border-radius: 10px 0 0 10px;
  padding: 16px 0 16px 8px;
  margin-left: 20px;
  vertical-align: middle;
  &-active {
    background-color: white;
    color: $main-color;
  }
}

.sidebar-item:hover:not(.sidebar-item-active) {
  background-color: #485498;
}
