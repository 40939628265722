.icon-width {
  width: 70px;
}

.L008-control {
  min-width: 80px;
}

.amount_label {
  font-size: large;
  font-weight: bold;
  margin: 20px 0;
}