@import "../../components/Variables.scss";

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 2rem;
  top: 2rem;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  display: none;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  max-width: 250px;
}

/* General sidebar styles */
.bm-menu {
  background-color: $main-color;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  padding-left: 10px;
}

/* Individual item */
.bm-item {
  display: inline-block;
  overflow-y: scroll;
}

.bm-item::-webkit-scrollbar {
  display: none;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
