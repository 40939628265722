.status_div {
  background-color: white;
  border-radius: 10px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 5px solid;
  cursor: default;
  margin-top: 1px;
  margin-bottom: 1px;
}

.status {
  &_red {
    border-color: #f25767;
  }

  &_blue {
    border-color: #84c1f6;
  }

  &_green {
    border-color: #59de9b;
  }

  &_gray {
    border-color: #767171;
  }

  &_white {
    border-color: white;
  }
}

.status {
  margin-bottom: 3px;
}