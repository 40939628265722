.inc_dec_button {
  width: 30px;
  border-radius: 5px;
  border: none;
}

.text_box {
  width: 100px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px !important;
}