.outside {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
  padding: 20px;
  // background-color: #fff3ff;
}

.menu {
  width: 229px;
  height: calc(100vh - 40px);
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu-responsive {
  width: 70px;
  position: absolute;
  top: 0;
  left: 0;
}

.center {
  flex: 1;
  display: flex;
  flex-direction: column;
  // background-color: white;
  // border-radius: 20px;
}

.right {
  width: 10%;
  height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  color: #b5b5b5;
  .profile-pic {
    background-color: #f6f6fc;
    border-radius: 30px;
    padding: 10px;
    position: relative;
    max-width: 80px;
    img {
      max-width: 100%;
    }
    .profile-pic-cog {
      position: absolute;
      right: 0;
      top: 0;
      width: 25px;
      height: 25px;
    }
    .profile-pic-online {
      position: absolute;
      left: 0;
      top: 0;
      width: 25px;
      height: 25px;
      background-color: #afffaf;
      border-radius: 25px;
    }
  }
}
