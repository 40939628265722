.custom_row {
  width: 100%;
  border-bottom: solid;
  border-width: 1px;
  border-color: rgb(191, 191, 191);
  height: auto;
  white-space: nowrap;
}

.row-title {
  min-width: 200px;
  width: 30%;
}

.content {
  width: 70%;
}
