.L001_tr:hover {
  background-color: #EBF9FF;
  cursor: pointer;
}

.L001_text_wrap {
  margin: 10px;
  vertical-align: middle;
  overflow: visible;
  word-wrap: break-word;
  white-space: normal;
}