@import "../Common.scss";

.add_content {
  &_container {
    @include common-container;
  }
  &_title {
    @include common-title;
  }
  &_inner {
    @include common-inner;
  }
}

.add_content_item_wrapper {
  width: 80%;
  display: flex;
  flex-direction: column;
  margin-top: 5%;
}

.add_content_item {
  width: 100%;
  display: flex;
  margin-top: 3%;
}

.add_content_item_left {
  @include common-form-title;
}

.add_content_item_right {
  width: 70%;
}

.add_content_item_input {
  width: 90%;
  height: 40px;
  padding-left: 10px;
}

.add_content_item_textarea {
  width: 90%;
  height: 400px;
  padding-left: 10px;
  resize: none;
}

.add_content_item_method {
  width: 100%;
  display: flex;
}

.add_content_item_button {
  width: 20%;
  height: 40px;
  border-radius: 15px;
  background-color: #f25767;
  margin-top: 5%;
  color: white;
  border: white;
}

.error-message {
  @include common-error-message-area;
  @include common-error-message;
  margin-top: 10px;
  text-align: left;
}
