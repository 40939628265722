@import "../../components/Variables.scss";

.switch_button {
  position : relative;
  margin: 5px;
  width : 160px;
  height: 44px;
}

.switch_button input[type="radio"] {
  display : none;
}

.switch_button label {
  display : block;
  position : absolute;
  top : 0;
  bottom : 0;
  left : 0;
  right : 0;
  text-align : center;
  line-height : 44px;
}

.switch_button #monthlyOn + label {
  right : 50%;
  border-radius : 10px 0 0 10px;
  background : #eee;
  color : #666;
  border-right : none;
}

.switch_button #monthlyOn:hover + label {
  background : #e6e4e4;
}

.switch_button #monthlyOn:checked +label {
  background : #84c1f6;
  color : #fff;
}

.switch_button #monthlyOn:checked:hover +label {
  background : #53acfa;
}

.switch_button #weeklyOn + label {
  left : 50%;
  border-radius : 0 10px 10px 0;
  background : #eee;
  color : #666;
  border-left : none;
}

.switch_button #weeklyOn:hover + label {
  background : #e6e4e4;
}

.switch_button #weeklyOn:checked +label {
  background : #84c1f6;
  color : #fff;
}

.switch_button #monthlyOn:checked:hover +label {
  background : #53acfa;
}

.w_buttons {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.w_table_area {
  width: auto;
  overflow-x: auto;
}

.w_table {
  width:fit-content;
  border-collapse: collapse;
  table-layout: fixed;
  border-spacing: 0;
}

.h_start_time {
  width: 80px;
  text-align: center;
  color: white;
  border: 2px solid #cacaca;
  &.weekday {
    background-color: $main-color;
  }
  &.holiday {
    background-color: #d5595c;
  }
  &_top {
    &.weekday {
      width: 80px;
  text-align: center;
  color: white;
      border: none;
      background-color: $main-color;
    }
    &.holiday {
      width: 80px;
  text-align: center;
  color: white;
      border: none;
      background-color: #d5595c;
    }
  } 
}

.h_title {
  text-align: center;
  color: white;
  width: 220px !important;
  &.weekday {
    background-color: $main-color;
  }
  &.holiday {
    background-color: #d5595c;
  }
}

.b_frames {
  border: 2px solid #cacaca;
  height: 70px;
  &.weekday {
    background-color: #fff;
  }
  &.holiday {
    background-color: #ffebeb;
  }
}

.icon_pointer {
  cursor: pointer;
  color: #666;
}

.help_header {
  display: flex;
  align-items: center;
}