$font-size-extra-small: 10px;
$font-size-small: 14px;
$font-size-medium: 16px;
$font-size-big: 20px;
$container-max-width: 1140px;
$main-color: #363f72;

.border {
  border: solid 0.2px #dadada;
  margin-top: 2%;
  width: 100%;
}
