
.custom_select {
  min-width: 70px;
  text-align: center;
}

.select {
  &_red {

    & .Select__control {
      background-color: #f25767;
    }

    & .select__option {
      background-color: #f25767;
    }
  }

  &_blue {
    background-color: #84c1f6;
  }

  &_green {
    background-color: #59de9b;
  }
}

.select_red {
    &__control {
      background-color: #f25767 !important;
    }
    &__indicator {
      color: #fff!important;
    }
    &__single-value {
      color: #fff!important;
    }
}
.select_blue {
  &__control {
    background-color: #84c1f6 !important;
  }
  &__indicator {
    color: #fff!important;
  }
  &__single-value {
    color: #fff!important;
  }
}
.select_green {
  &__control {
    background-color: #59de9b !important;
  }
  &__indicator {
    color: #fff!important;
  }
  &__single-value {
    color: #fff!important;
  }
}