@import "../Common.scss";

.D002_select {
  min-width: 150px;
  margin: 0 0.5rem;
}

.D002_check {
  flex-wrap: wrap;
}
