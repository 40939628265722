.custom_button {
  color: white;
  border: none;
  border-radius: 10px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  white-space: nowrap;
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    background-color: #6c757d;
  }
}

.round_button {
  color: white;
  border: none;
  border-radius: 40%;
  margin-left: 1px;
  margin-right: 1px;
  white-space: nowrap;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.cb_size_m {
  padding: 10px 20px;
  font-size: 16px;
}

.cb_size_s {
  padding: 5px 10px;
  font-size: 14px;
}

.button {
  &_blue {
    background-color: #84c1f6;
  }
  &_blue:hover,
  &_blue:active {
    background-color: #53acfa;
  }
  &_blue:focus {
    background-color: #349bf5;
  }
  &_red {
    background-color: #f25767;
  }
  &_red:hover,
  &_red:active {
    background-color: #ff243a;
  }
  &_red:focus {
    background-color: #ff1129;
  }
  &_green {
    background-color: #59de9c;
  }
  &_green:hover,
  &_green:active {
    background-color: #30cf80;
  }
  &_green:focus {
    background-color: #11b663;
  }
  &_yellow {
    background-color: #c0c247;
  }
  &_yellow:hover,
  &_yellow:active {
    background-color: #abad2b;
  }
  &_yellow:focus {
    background-color: #989b11;
  }
  &_gray {
    background-color: #6c757d;
  }
  &_gray:hover,
  &_gray:active {
    background-color: #4d5257;
  }
  &_gray:focus {
    background-color: #3e4145df;
  }
  &_none,
  &_none:hover,
  &_none:active,
  &_none:focus {
    background-color: #eeeeee;
    color: #8d8d8d;
  }
  &_light_gray {
    background-color: #eeeeee;
    color: #8d8d8d;
  }
  &_light_gray:hover,
  &_light_gray:active {
    background-color: #dddddd;
    color: #8d8d8d;
  }
  &_light_gray:focus {
    background-color: #d4d4d4;
    color: #8d8d8d;
  }
  &_red_shadow {
    background-color: #eeeeee;
    color: #8d8d8d;
    box-shadow: 0 0 10px #f25767;
  }
  &_red_shadow:hover,
  &_red_shadow:active,
  &_red_shadow:focus {
    background-color: #dddddd;
    color: #8d8d8d;
    box-shadow: 0 0 10px #f25767;
  }
}
