@import "../Common.scss";

.program_sheet_list {
  &_container {
    @include common-container;
  }
  &_title {
    @include common-title;
  }
  &_inner {
    @include common-inner;
  }
}

.program_sheet_list_header_area {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.program_sheet_list_add_button {
  width: 30%;
  height: 50px;
  background-color: #f25767;
  color: white;
  border-radius: 15px;
  font-size: x-large;
  margin-top: 1%;
  border: white;
  font-weight: bold;
}

.program_sheet_list_table {
  margin-top: 3%;
}
