.chat_room_name {
  margin: 0;
  background-color: #ececf5;
  height: 100px;
  line-height: 90px;
  font-size: 20px;
  padding-left: 20px;
  @media (min-width: 768px) {
    font-size: 26px;
  }
}

.chat_log_items {
  padding: 3%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.message_send_area {
  height: auto;
  background-color: #edf1ee;
  padding: 20px 10px;
  width: auto;
  vertical-align: top;
  display: flex;
}

.message_input {
  width: 85%;
}

.message_icon {
  padding: 5px;
  width: 50px;
  height: 50px;
  text-align: center;
}