.weekly_name_button {
  width: 95%;
  height: 50px;
  border-radius: 10px;
  justify-content: space-between;
  cursor: pointer;
}

.weekly_name_label {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.weekly_select_staff:active, 
.weekly_select_staff:focus-within {
  z-index: 5;
}

.select_option {
  margin: auto 7px;
  color: black;
  font-size: small;
  min-width: 80px;
}

.wnb {
  &_blue {
    background-color: #84c1f6;
    color: #fff;
    border: none;
  }
  &_blue:hover,
  &_blue:active {
    background-color: #53acfa;
  }
  &_blue:focus {
    background-color: #349bf5;
  }
  &_red {
    background-color: #f25767;
    border: none;
  }
  &_red:hover,
  &_red:active {
    background-color: #ff243a;
  }
  &_red:focus {
    background-color: #ff1129;
  }
  &_yellow {
    background-color: #fff5d7;
    border: 4px solid #ffe699;
  }
  &_yellow:hover,
  &_yellow:active {
    background-color: #fff1c8;
  }
  &_yellow:focus {
    background-color: #ffeebb;
  }
  &_gray {
    background-color: #c3c9ce;
    color: #fff;
    border: none;
  }
  &_gray:hover,
  &_gray:active {
    background-color: #a7acb1;
  }
  &_gray:focus {
    background-color: rgba(170, 171, 173, 0.875);
  }
  &_pink {
    background-color: #ffdfe3;
    color: #8d8d8d;
    border: none;
  }
  &_pink:hover,
  &_pink:active {
    background-color: #fcd5d9;
  }
  &_pink:focus {
    background-color: #facfd4;
  }
  &_none {
    background-color: #ffffff;
    color: #8d8d8d;
    border: 4px solid #b7b7b7;
  }
  &_none:hover,
  &_none:active,
  &_none:focus {
    background-color: #f5f5f5;
  }
}

.weekly_add_slot_button {
  border: 3px solid #b5d8f8;
  background-color: #fff;
  width: 95%;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.weekly_add_slot_button:hover,
.weekly_add_slot_button:active,
.weekly_add_slot_button:focus {
  background-color: #e8f4ff;
  border-color: #84c1f6;
}

.sc_yellow {
  &__control {
    background-color: #ffe699 !important;
  }
}

.describe_icon {
  width: 25%;
  display: flex;
  pointer-events: none;
}

.describe_text {
  width: 75%;
  text-wrap: wrap;
}

.help_modal_select_staff {
  background-color: #fff;
  border-radius: 5px;
  color: #000000;
  padding: 6px;
  width: 40px;
  margin: auto 7px;
  font-size: small;
}

.reserved {
  display: flex;
  align-items: center;
  padding-left: 6px;
}