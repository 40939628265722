.balloonOya {
    position: relative;
}

.balloonOya:hover .balloon {
    display: inline;
}

.balloon {
    position: absolute;
    display: none;
    padding: 15px;
    background-color: #84c1f6;
    color: white;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    margin-top: 12px;
    z-index: 1;
    border-radius: 10px;
}

.balloon:after {
    border-bottom: 12px solid #84c1f6;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    content: "";
    position: absolute;
}