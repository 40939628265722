.custom_row_split_two {
  width: 100%;
  border-bottom: solid;
  border-width: 1px;
  border-color: rgb(191, 191, 191);
  height: auto;
  white-space: nowrap;
}

.row_title_30 {
  width: 30%;
}

.content_20 {
  width: 20%;
}
