@import "../../components/Variables.scss";

.calender-main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-calender {
  width: 100%;
}

.headers {
  height: 35px;
  background-color: $main-color;
  color: white;
}

.header_title {
  text-align: center;
}

.item-day {
  height: 55px;
  border: 1px solid #dcdcdc;
}