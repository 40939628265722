@import "../../components/Variables.scss";

.calendar_container {
  width: 100%;
}

.calendar_buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cl_btn {
  width: 15%;
  height: 40px;
  border-radius: 20px;
  border: none;
  background-color: $main-color;
  color: white;
  font-weight: 500;
}

.cl_date_text {
  text-align: center;
  margin-top: 0.5%;
  margin-bottom: 0.5%;
}

.cl_table_aea {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cl_table {
  width: 100%;
}

.cl_head {
  height: 35px;
  background-color: $main-color;
  color: white;
}

.cl_head_title {
  text-align: center;
  width: 14.5%;
}

.cl_day_default {
  text-align: center;
  height: 70px;
  border: 1px solid #dcdcdc;
  font-weight: 500;
}

.cl_day_holiday {
  text-align: center;
  height: 70px;
  border: 1px solid #dcdcdc;
  font-weight: 500;
  background-color: rgb(231, 231, 231);
  color: gray;
}

.cl_day_green {
  text-align: center;
  height: 55px;
  border: 1px solid #dcdcdc;
  font-weight: 500;
  cursor: pointer;
  background-color: green;
}

.cl_day_red {
  text-align: center;
  height: 55px;
  border: 1px solid #dcdcdc;
  font-weight: 500;
  cursor: pointer;
  background-color: red;
}

// .move_calendar {
//   cursor: pointer;
// }