%text_style {
    line-height: 25px;
    font-size: 18px;
    border-radius: 12px;
    word-break: break-all;
    padding: 5px 20px;
    margin: 10px 0;
}

.chat_mime {
display: flex;
align-items: center;
flex-direction: row-reverse;
margin: 10px 0;
}

.chat_you {
display: flex;
img {
    border-radius: 50%;
}
align-items: center;
margin: 10px 0;
}



.bubble {
    @extend %text_style;
    padding: 10px 20px;
    width: auto;
    position: relative;
}
.bubble:after {
    content: "";
    position: absolute;
    border: 8px solid transparent;
}


.chat_you .bubble {
    background: #edf1ee;
    margin-left: 20px;
}
.chat_you .bubble:after {
    top: 3px;
    left: -19px;
    border-right: 18px solid #edf1ee;
    transform: rotate(35deg);
}

.chat_mime .bubble {
    background: #30e852;
    margin-right: 20px;
}
.chat_mime .bubble:after {
    top: 3px;
    right: -19px;
    border-left: 18px solid #30e852;
    transform: rotate(-35deg);
}

.send_info{
    flex-direction: column;
    text-align: center;
    font-size: 12px;
}

.send_date {
    display: flex;
    justify-content: center;
    p {
        background-color: lightgray;
        border-radius: 15px;
        font-size: small;
        padding: 0 15px;
    }
}