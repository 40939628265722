@import "../Common.scss";

.fc_details {
  &_container {
    @include common-container;
  }
  &_title {
    @include common-title;
  }
  &_inner {
    @include common-inner;
  }
}

.fc_details_item_wrapper {
  width: 80%;
  display: flex;
  margin-top: 5%;
  flex-direction: column;
}

.fc_details_item {
  width: 100%;
  display: flex;
  margin-top: 3%;
}

.fc_details_item_title_area {
  @include common-form-title;
}

.fc_details_item_title {
  color: #323232;
}

.fc_facilities_form_title {
  @include common-form-title;
}

.fc_facility_form {
  width: 75%;
  & > select {
    width: 50%;
  }
}

.fc_facility_form_input {
  width: 80%;
  font-size: large;
  padding: 10px;
}

.fc_details_item_body_area {
  width: 60%;
}

.fc_details_item_body {
  margin-top: 2%;
  font-size: large;
}

.fc_details_item_border {
  border: solid 0.2px #dadada;
  margin-top: 2%;
  width: 100%;
}

.fc_details_item_body_button {
  width: 30%;
  height: 40px;
  background-color: #84c1f6;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  font-size: large;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit_and_delete_buttons {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-left: auto;
}
