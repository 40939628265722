@import "../Common.scss";

.edit_content {
  &_container {
    @include common-container;
  }
  &_title {
    @include common-title;
  }
  &_inner {
    @include common-inner;
  }
}

.edit_content_item_wrapper {
  width: 80%;
  display: flex;
  flex-direction: column;
  margin-top: 5%;
}

.edit_content_item {
  width: 100%;
  display: flex;
  margin-top: 3%;
}

.edit_content_item_left {
  @include common-form-title;
}

.edit_content_item_right {
  width: 70%;
}

.edit_content_item_input {
  width: 90%;
  height: 40px;
  padding-left: 10px;
}

.edit_content_item_textarea {
  width: 90%;
  height: 400px;
  padding-left: 10px;
  resize: none;
}

.edit_content_item_video {
  width: 90%;
  height: 400px;
  padding-left: 10px;
}

.edit_content_item_input_array {
  width: 90%;
  height: 40px;
  padding-left: 10px;
  margin-top: 2%;
}

.edit_content_item_button {
  width: 20%;
  height: 40px;
  border-radius: 15px;
  background-color: #f25767;
  margin-top: 5%;
  color: white;
  border: white;
  margin-bottom: 5%;
}
