.custom_image {
    width: 70%;
}

.custom_image_preview {
    width: 100%;
    height: 250px;

    >img {
        width: 100%;
        height: 250px;
        object-fit: contain;
    }
}

.custom_image_upload_input {
    width: 30%;
    height: 40px;
    margin: 3% auto 0;
    background-color: #707070;
    border: none;
    cursor: pointer;
    color: white;
    font-size: large;
    display: block;
}

.custom_image_upload {
    width: 100%;
    height: 250px;
    background-color: #fcfcfd;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    border: 2px dashed #c7caca;
}

.custom-user-icon {
    bottom: -19px;
    right: -29px;
}