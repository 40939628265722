@import "../Common.scss";

.D001_select {
  min-width: 150px;
}

.name_like_search {
  max-width: 800px;
}

.endFlg_check {
  padding: 0 16px;
  margin: auto 0;
}
