@import "../Common.scss";

.schedule {
  &_container {
    @include common-container;
  }
  &_title {
    @include common-title;
  }
}

.cl_area {
  width: 100%;
}

.form-control:disabled, .form-control[readonly] {
  background-color: rgba(239, 239, 239, 0.3);
  border-radius: 0;
}

.button_area {
  margin: 0 auto;
  width: 90%;
  margin-top: 2%;
}

.K001_select {
  min-width: 150px;
}

.move_calendar {
  cursor: pointer;
}

.warning-text {
  font-size: large;
  font-weight: bold;
  margin: 5px auto;
}

.warning-contents {
  background-color: #ffff00;
  text-align: center;
  margin-bottom: 2rem;
}