@import "../Common.scss";

.dashboard_menu_container {
  background-color: #f6f6fc;
  border-radius: 15px;
  padding: 25px 25px;
  min-width: 800px;
}

.dashboard_menu {
  display: flex;
  align-items: first baseline;
  justify-content: space-between;
}

.dashboard_menu_item {
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  background-color: white;
  border: white;

  h6 {
    margin: 0;
  }

  &:first-child {
    margin-top: 0;
  }
}

.dashboard_menu_under {
  padding: 10px 20px;
  border-radius: 15px;
  background-color: white;
  border: white;
  margin-top: 20px;

  h5 {
    margin: 0;
  }

  &:first-child {
    margin-top: 0;
  }
}

.dashboard_menu td:first-child {
  text-align: left !important;
}

.B001_table td:first-child {
  width: 80%;
}

.B001_table td:first-child+td {
  text-align: right;
  width: 10%;
}

.dashboard_menu tr {
  height: 30px;
}

.B001_hover:hover {
  background-color: #EBF9FF;
}

.B001_table_title {
  font-size: 14px;
  border-bottom: 1px #eee solid;
  color: #999999
}

.dashboard_item_detail {
  width: 100%;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 10px;
}

.approval_pending_title {
  font-weight: bold;
}

.approval_pending_body {
  padding-left: 40px;
}

@mixin menu_item_title($bg: #f25767) {
  width: 100%;
  height: 40px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $bg;
  color: white;
}

.red {
  @include menu_item_title();
}

.green {
  @include menu_item_title(#31d684);
}

.light_green {
  @include menu_item_title(#7beeb4);
}

.purple {
  @include menu_item_title(#a974ff);
}

.yellow {
  @include menu_item_title(#f7c700);
}

.orange {
  @include menu_item_title(#ffa263);
}

.pink {
  @include menu_item_title(#ff70b8);
}

.dark_pink {
  @include menu_item_title(#e6348d);
}

.blue {
  @include menu_item_title(#3cb6fd);
}

.light_blue {
  @include menu_item_title(#51dafc);
}

.light_red {
  @include menu_item_title(#f85880);
}

.unread_count {
  width: 35px;
  min-width: 35px;
  background-color: #4bf26a;
  height: 35px;
  border-radius: 50%;
  text-align: center;
  line-height: 35px;
  color: #fff;
}

@media (min-width: 1300px) {
  .B001_plate {
    margin-left: auto;
    width: 95%;
    margin-top: 2%;
  }
}
