@import "../Common.scss";

.fc_staff_sheet {
  &_container {
    @include common-container;
  }
  &_title {
    @include common-title;
  }
  &_inner {
    @include common-inner;
  }
}

.fc_staff_sheet_table {
  width: 90%;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
}

.create_fc_staff_button_area {
  width: 100%;
  margin-top: 3%;
}

.create_fc_staff_button {
  width: 15%;
  height: 40px;
  background-color: #f25767;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 500;
  font-size: large;
  border-radius: 10px;
  border-color: white;
  margin-left: 70%;
  cursor: pointer;
}