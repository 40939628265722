.custom_video {
    width: 90%;
    height: 400px;
    padding-left: 10px;
}

.custom_video_right {
    width: 70%;
}

.custom_video_input {
    width: 90%;
    height: 40px;
    padding-left: 10px;
}