.custom_panel {
  color: black;
  background-color: rgb(231, 231, 231);
  border: none;
  width: 100%;
  height: 100%;
  padding: auto;
  border-radius: 0px;
  &:disabled {
    color: gray;
    cursor: not-allowed;
    background-color: rgb(231, 231, 231);
  }
}

.panel {
  &_none {
    background-color: #fff;
  }
  &_none:hover,
  &_none:active {
    color: black;
    background-color: #eeb8c0;
  }
  &_none:focus {
    outline: none;
    box-shadow: none;
    color: black;
    background-color: #fff;
    border: 3px solid #9ec5fe;
  }
  &_red {
    background-color: #f3e5e7;
  }
  &_red:hover,
  &_red:active {
    color: black;
    background-color: #eeb8c0;
  }
  &_red:focus {
    outline: none;
    box-shadow: none;
    color: black;
    background-color: #f3e5e7;
    border: 3px solid #9ec5fe;
  }
  &_gray:disabled {
    color: gray;
    cursor: not-allowed;
    background-color: #e7e7e7;
  }
  &_more_gray:disabled {
    color: rgb(177, 173, 173);
    cursor: not-allowed;
    background-color: #fdf9f9;
  }
  
}