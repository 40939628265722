.chat-container {
  height: 93vh;
}

.chat_rooms_container {
  border-right: 1px solid #ddd;
  padding: 3px;
}

.chat_rooms_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  flex: 1;
  > p {
    margin-bottom: 0;
  }
}

.chat_rooms {
  overflow: scroll;
  height: 78vh;
  min-width: 80px;
}

.chat_rooms_item {
  cursor: pointer;
  align-items: center;
  padding: 1rem;
  height: 100px;
  > p {
    margin: 0 0 0 0.5em;
  }
}

.chat_rooms .unread_count {
  width: 35px;
  min-width: 35px;
  background-color: #4bf26a;
  height: 35px;
  border-radius: 50%;
  text-align: center;
  line-height: 35px;
  color: #fff;
}

.chat_rooms::-webkit-scrollbar {
  display: none;
}

.G001_form {
  max-width: 250px;
  margin: 3px 0 !important;
}