@import "../Common.scss";

#pp_bg {
  width: 90%;
  background-color: #f4fcff;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  border-radius: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

#pp_main {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

// 文字
.main_title {
  text-align: center;
  margin: 10px;
  font-weight: bold;
}

.sub_title {
  text-align: center;
  margin: 10px;
  font-weight: 500;
}

.paragraph_title {
  padding: 5px 0;
  font-size: 26px;
  font-weight: bold;
}

.paragraph_subtitle {
  font-size: 20px;
  padding: 8px 0;
  font-weight: 650;
}

.sub_section {
  font-size: 18px;
  padding: 5px 0;
}

// セクション
.chapter {
  padding: 2% 0;
}

.clause {
  padding: 0 1rem;
}

.section {
  padding: 0 1rem;
}

.pp_link {
  padding-left: 10px;
}