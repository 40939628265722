@import "./Common.scss";
@import "../../components/Variables.scss";
@import "../Common.scss";

.sic_kids_admin_auth {
  @include outline;
  a {
    color: #374abe;
    border-bottom: 2px solid #374abe;
    text-decoration: none;
  }
  &_title {
    @include title;
  }
  &_container {
    @include container;
  }
  &_inner {
    @include inner;
  }
  &_label {
    @include label;
  }
  &_error-message {
    @include common-error-message-area;
  }
  &_input_area {
    @include input_area;
  }
  &_input {
    @include input;
    margin-bottom: 0;
  }
  &_btn {
    @include btn;
  }
  &_border {
    @include border;
  }
  &_forget {
    text-align: center;
    padding: 10% 0;
    font-size: $font-size-small;
    @media (min-width: 576px) {
    }
    @media (min-width: 768px) {
      font-size: $font-size-medium;
    }
    @media (min-width: 992px) {
    }
    @media (min-width: 1200px) {
    }
    a:hover {
      text-decoration: none;
    }
  }
}

.error-message {
  @include common-error-message;
}

.login_text {
  max-width: 450px;
  height: 50px;
}

.login_alert_div {
  text-align: center;
  width: 100%;
}

.login_alert {
  display: inline-block;
  font-size: 14px;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.login_btn {
  width: 100%;
}

.sic_kids_admin_auth_container {
  min-height: 70vh;
}

.login_no_scroll::-webkit-scrollbar {
  display: none;
}