@import "../Common.scss";

#contact_bg {
  width: 90%;
  background-color: #f4fcff;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  border-radius: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

#contact_title {
  text-align: center;
  margin-top: 2%;
  margin-bottom: 2%;
}

#main {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}