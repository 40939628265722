@import "../components/Variables.scss";

@mixin common-container {
  width: 100%;
  height: 100%;
}

@mixin common-title {
  margin-left: 5%;
  margin-top: 1%;
}

@mixin common-inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin common-error-message-area {
  height: auto;
  color: red;
  font-size: medium;
  font-weight: 600;
  text-align: left;
  margin-top: 30px;
  line-height: 1.5;
}

@mixin common-error-message {
  display: block;
}

@mixin common-form-title {
  width: 30%;
  display: flex;
  justify-content: start;

  align-items: center;
  color: #323232;

  > p {
    font-size: $font-size-big;
    margin-bottom: 0;
    padding-right: 1rem;
  }
}

@mixin common-borderline-light {
  width: 100%;
  border-bottom: solid 0.5px #dadada;
}

@mixin common-hover-action {
  background: rgba(0, 0, 0, 0.075);
}

.plate {
  margin-left: auto;
  width: 95%;
  margin-top: 2%;
}

.plate_no_margin {
  margin-left: auto;
  width: 95%;
}

.on_same_row {
  display: flex; /* Use flexbox to place elements in a row */
  align-items: center;
}

.item {
  margin: 10px;
  vertical-align: middle;
  white-space: nowrap;
}

.wave_str {
  margin: 5px;
}

.cursor_default:hover {
  cursor: default;
}

.hover_white:hover {
  background-color: white;
}

.justify {
  justify-content: space-between;
}

.justify_center {
  justify-content: center;
}

.margin_1 {
  margin-top: 1%;
}

.t_align_center {
  text-align: center;
}

.hide_over {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align_center {
  align-items: center;
}

.display_flex {
  display: flex;
}