@import "../../components/Variables.scss";

@mixin outline {
  padding: 3% 0;
  background-color: #f2f2f2;
  min-height: 100vh;
}

@mixin container {
  background-color: white;
  width: 80%;
  margin: 0 auto;
  padding: 1.5rem;
  max-width: 768px;
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
}

@mixin title {
  text-align: center;
  padding: 1.5rem 0;
  margin-bottom: 3rem;
  font-size: $font-size-big;
  @media (min-width: 576px) {
    font-size: 2.5rem;
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
}

@mixin btn {
  background-color: #f25767;
  border: none;
  color: white;
  border-radius: 5px;
  transition: 0.2s;
  cursor: pointer;
  width: 70%;
  height: 70px;
  max-width: 450px;
  margin-top: 20px;
  margin-bottom: 50px;
  &:hover {
    background-color: #ff243a;
  }
  &:focus {
    background-color: #ff1129;
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
    font-size: $font-size-big;
  }
  @media (min-width: 1200px) {
  }
}

@mixin label {
  color: #323232;
  margin: 0;
  font-size: $font-size-big;
  width: 35%;
  @media (max-width: 576px) {
    font-size: $font-size-small;
  }
  @media (min-width: 768px) {
    font-size: $font-size-big;
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
}

@mixin input {
  width: 60%;
  margin: 0 auto;
  padding: 5px;
  border: 2px solid #dcdcdc;
  @media (max-width: 576px) {
    width: 100%;
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
}

@mixin text {
  color: #323232;
  margin-bottom: 3rem;
  padding: 0 5%;
  font-size: $font-size-big;
}

@mixin input_area {
  width: 90%;
  padding-bottom: 20px;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  // border-bottom: 2px solid #DADADA;
  @media (max-width: 576px) {
    display: flex;
    flex-direction: column;
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 992px) {
  }
  @media (min-width: 1200px) {
  }
}

@mixin inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin border {
  border-bottom: medium solid aqua;
  width: 80%;
}
